import $ from 'jquery';

// Check if element is in the viewport of the screen
$.fn.isInViewport = function fn() {
  const elementTop = $(this).offset().top;
  const elementBottom = elementTop + $(this).outerHeight();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

// Will remove the top padding on project and blog pages
function fixTop() {
  const trigger = $('.wrapper-share');

  if (trigger.length >= 1) {
    $('.content-wrapper').addClass('no-pad-xl');
  }
}

function optionsSharing() {
  const shareBut = $('#share');
  const shareOpt = $('.share-options');
  shareBut.on('click', function expandList(e) {
    e.preventDefault();
    if ($(this).hasClass('expanded')) {
      shareOpt.slideUp(200);
      $(this).removeClass('expanded');
    } else {
      shareOpt.slideDown(200);
      $(this).addClass('expanded');
    }
  });
}

/**
 * Dedicated helper function to apply the filter visually
 * @param {string} categoryName - name of the selected category
 * @param {object} toFiltter - container element where the filter is used
 */
function applyFilter(categoryName, toFilter) {
  const container = $(toFilter);
  container.find('[data-category]').css({ display: 'block' });

  if (categoryName.toLowerCase() !== 'all') {
    container
      .find(`[data-category]:not([data-category="${categoryName}"])`)
      .css({ display: 'none' });
  }
}

/**
 * Enables the infinite scrolling/loading for appearances or upcoming items
 * @param {string} triggerSelector - container-element which will trigger the loading
 * when in viewport.
 * @param {string} httpString - url string which is used as to identify the finished ajax call.
 */
function initInfiniteScroll(triggerSelector, httpString) {
  const trigger = $(triggerSelector);
  let loadLock = false;

  if (trigger.length) {
    $(window).on('scroll', () => {
      if (trigger.isInViewport() && !loadLock) {
        loadLock = true;
        trigger.find('a').trigger('click');
      }

      $(document).ajaxComplete((event, xhr, settings) => {
        if (settings.url.split('?')[0] === httpString) {
          loadLock = false;

          // Apply filter after ajax call is finished
          const categoryName = $('.dropdown > a > span').text();
          if (categoryName.toLowerCase() !== 'all') {
            applyFilter(categoryName, '#articles');
          }
        }
      });
    });
  }
}

/**
 * Enables to filter the toFilter container.
 * Make sure to include the 'data-category' attribute to the filtered elements.
 * @param {string} triggerSelector - container-element in which the a tag is enclosed.
 * @param {string} toFilter - container in which the elements to filter are enclosed.
 */
function categoryFilter(triggerSelector, toFilter) {
  const dropdownContainer = $(triggerSelector);

  dropdownContainer.each((idx, el) => {
    const element = $(el);
    const trigger = element.find('> a');
    const dropdown = element.find('> ul');
    const categories = dropdown.find('li > a');

    categories.click(function click(e) {
      e.preventDefault();

      const categoryName = $(this).text();

      trigger.find('span').text(categoryName);
      dropdown.slideUp(200);
      trigger.removeClass('expanded');

      applyFilter(categoryName, toFilter);
    });

    trigger.on('click', function expandList(e) {
      e.preventDefault();
      if ($(this).hasClass('expanded')) {
        dropdown.slideUp(200);
        $(this).removeClass('expanded');
      } else {
        dropdown.slideDown(200);
        $(this).addClass('expanded');
      }
    });
  });
}

/**
 * Adds the modal open/close logic to a trigger and the modal container
 * @param {string} triggerSelector - element that triggers the opening of the modal on click
 * @param {string} containerSelector - container element of the actual modal
 */
function modalHandler(triggerSelector, containerSelector) {
  const content = $('.content-wrapper');
  const navWrapper = $('.wrapper-nav');
  const navi = $('.navigation');
  const header = $('#header');
  const logoWrapper = header.find('.logo-wrapper');
  const logo = header.find('.logo');
  const hamburger = header.find('.hamburger');
  const menuBut = header.find('.menu-but');
  const modals = $('.bottom-modal').not(containerSelector);
  const closeInfoPanel = $('.close-panel');

  const trigger = $(triggerSelector);
  const modal = $(containerSelector);
  const closeModal = modal.find('.close-modal');

  trigger.on('click', function click() {
    if ($(this).hasClass('opened')) {
      $(this).removeClass('opened');
      modal.removeClass('expanded-s');
    } else {
      if (navi.hasClass('visible')) {
        setTimeout(() => {
          modal.addClass('expanded-s');
        }, 500);
      } else {
        modal.addClass('expanded-s');
      }

      $(this).addClass('opened');
      modals.removeClass('expanded-s');
      content.removeClass('animated');
      navWrapper.removeClass('visible');
      navi.removeClass('visible');
      header.removeClass('navicolor');
      hamburger.removeClass('is-active');
      menuBut.removeClass('opened');
      logoWrapper.removeClass('navopen');
      logo.removeClass('inversed');
      closeInfoPanel.trigger('click');
    }
  });

  closeModal.on('click', () => {
    modal.removeClass('expanded-s');
    trigger.removeClass('opened');
  });
}

export {
  fixTop, optionsSharing, categoryFilter, initInfiniteScroll, modalHandler, applyFilter,
};
