import ajaxCall from '../lib/ajaxCall';
import { categoryFilter as initFilter, initInfiniteScroll } from '../lib/helper';

function initLoadMore() {
  ajaxCall({
    template: 'upcoming.json',
    appendToSelector: '#articles',
    triggerSelector: '#LoadMore',
  });
}

export default {
  init() {
    // JavaScript to be fired on the people page
    initLoadMore();
    initFilter('.dropdown', '#articles');
    initInfiniteScroll('#LoadMore', '/upcoming.json');
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
