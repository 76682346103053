import $ from 'jquery';

function ajaxCall({ appendToSelector, triggerSelector, template }) {
  const el = $(triggerSelector);
  const button = $(el).find('a');
  const icon = button.find('.add');

  if (el.length >= 1) {
    const { dataset } = el.find('a').get(0);

    button.click(() => {
      icon.addClass('loading');
      $.ajax({
        url: `/${template}?page=${dataset.page}&limit=${dataset.limit}&total=${dataset.total}`,
        dataType: 'json',
        success(arr) {
          $(appendToSelector).append(arr[0]);
          icon.removeClass('loading');

          if (!$.isEmptyObject(JSON.parse(arr[1]))) {
            const { page, limit, total } = JSON.parse(arr[1]);
            button
              .attr('data-page', page)
              .attr('data-limit', limit)
              .attr('data-total', total);
          } else {
            button.remove();
          }
        },
      });
    });
  }
}

export default ajaxCall;
