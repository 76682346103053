import $ from 'jquery';

const disqusShortname = 'moovellab';

export default function disqus() {
  // eslint-disable-next-line
  function disqus_config() {
    this.page.url = 'a unique URL for each page where Disqus is present';
    this.page.identifier = 'a unique identifier for each page where Disqus is present';
    this.page.title = 'a unique title for each page where Disqus is present';
  }

  const d = document;
  const s = d.createElement('script');
  const loadC = $('#load-comments');
  const disqussThread = $('#disqus_thread');

  if (disqussThread.length > 0) {
    s.src = `https://${disqusShortname}.disqus.com/embed.js`;

    s.setAttribute('data-timestamp', +new Date());
    (d.head || d.body).appendChild(s);

    loadC.click(() => {
      loadC.fadeOut(200);
      disqussThread.fadeIn(500);
    });
  }
}
