import $ from 'jquery';

export default function slickSlider() {
  const galleries = $('article .gallery, article .slider');

  galleries.each((idx, el) => {
    const gallery = $(el);
    const sliderMode = $(el).hasClass('slider');
    const slideCount = gallery.find('.counter');
    const slickSlide = gallery.find('.wrapper-slider-gallery');
    const sliderNavi = gallery.find('.wrapper-slider-navigation');

    let slickBaseOptions = {
      mobileFirst: true, // add this one
      prevArrow: gallery.find('.prevclick, .prev'),
      nextArrow: gallery.find('.nextclick, .next'),
      // slidesToShow: 1,
      centerMode: true
    };

    if (sliderMode) {
      slickBaseOptions = {
        ...slickBaseOptions,
        asNavFor: '.wrapper-slider-navigation',
        infinite: false,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              centerPadding: '20%'
            }
          },
          {
            breakpoint: 768,
            settings: {
              centerPadding: '15%',
              slidesToShow: 1,
              centerMode: true
            }
          },
          {
            breakpoint: 300,
            settings: {
              centerPadding: 0
            }
          }
        ]
      };
    } else {
      slickBaseOptions = {
        ...slickBaseOptions,
        fade: true,
        duration: 700,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        touchThreshold: 100,
        infinite: false,
        centerPadding: 0,

        responsive: [
          {
            breakpoint: 1280
          },
          {
            breakpoint: 768,
            settings: {
              centerMode: true
            }
          },
          {
            breakpoint: 300
          }
        ]
      };
    }

    gallery
      .find('.wrapper-slider-gallery')
      .on('init reInit afterChange', (event, slick, currentSlide) => {
        const i = (currentSlide || 0) + 1;
        slideCount.text(`${i}/${slick.slideCount}`);
      });

    gallery
      .find('.wrapper-slider-gallery')
      .on('afterChange init', (event, slick) => {
        slick.$slides.removeClass('prevdiv').removeClass('nextdiv');

        if (slick.slideCount > 0) {
          $(slick.$slides.get(slick.slideCount - 1)).addClass('slick-last');
        }

        // find current slide
        for (let i = 0; i < slick.$slides.length; i += 1) {
          const $slide = $(slick.$slides[i]);
          if ($slide.hasClass('slick-current')) {
            // update DOM siblings
            $slide.prev().addClass('prevdiv');
            $slide.next().addClass('nextdiv');
            break;
          }
        }
      })
      .on('beforeChange', (event, slick) => {
        // optional, but cleaner maybe
        // remove all prev/next
        slick.$slides.removeClass('prev').removeClass('next');
      });

    slickSlide.slick(slickBaseOptions);

    sliderNavi.slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.wrapper-slider-gallery',
      dots: false,
      centerMode: false,
      focusOnSelect: false
    });

    gallery.find('.wrapper-slider-navigation .slick-slide').on('click', function click() {
      gallery.find('.wrapper-slider-gallery').slick('slickGoTo', $(this).data('slickIndex'));
    });

    gallery.find('.prevclick').click(() => {
      slickSlide.slick('slickPrev');
    });
    gallery.find('.nextclick, .wrapper-slider-gallery .image').click(() => {
      slickSlide.slick('slickNext');
    });
  });
}
