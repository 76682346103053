function cheatCodes() {
  let sequence = [];
  const barrelroleTrigger = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown'];
  const moneyRainTrigger = [
    'KeyE',
    'KeyV',
    'KeyE',
    'KeyR',
    'KeyY',
    'KeyO',
    'KeyN',
    'KeyE',
    'KeyI',
    'KeyS',
    'KeyR',
    'KeyI',
    'KeyC',
    'KeyH',
  ];

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    // eslint-disable-next-line no-plusplus
    for (let i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  const barrelrole = () => {
    const el = document.body;
    el.style.animationDuration = '4s';
    el.style.animationName = 'spinning';
    console.log('barrelrole!');

    setTimeout(() => {
      el.style.animationDuration = '';
      el.style.animationName = '';
    }, 4000);
  };

  const moneyRain = () => {
    console.log('rich!');
  };

  const triggerTimeFrame = () => {
    setTimeout(() => {
      if (arraysEqual(sequence, barrelroleTrigger)) {
        barrelrole();
      }

      if (arraysEqual(sequence, moneyRainTrigger)) {
        moneyRain();
      }

      sequence = [];
    }, 4000);
  };

  document.addEventListener('keydown', (event) => {
    if (sequence.length === 0) {
      triggerTimeFrame();
    }

    sequence.push(event.code);
  });
}

function tinylab() {
  document.body.style.transitionProperty = 'transform';
  document.body.style.transitionDuration = '4s';
  document.body.style.transform = 'scale(0.1)';
}

function shakerspace() {
  const divs = document.getElementsByTagName('div');
  for (let i = 0; i < divs.length; i += 1) {
    // do something to each div like
    divs[i].style.animationName = 'shake';
    divs[i].style.animationDuration = '0.5s';
    divs[i].style.animationIterationCount = 'infinite';
  }
}

function rtfm() {
  console.log('cheatCodes:');
  console.log('desc: input the key sequence on the website');
  console.log('   barrelrole - code: ArrowUp ArrowUp ArrowDown ArrowDown');
  console.log('   moneyRain - code: everyoneisrich');

  console.log('console commands:');
  console.log('   tinylab - func: tinylab()');
  console.log('   shakerspace - func: shakerspace()');
}

function consoleFun() {
  window.rtfm = rtfm;
  window.tinylab = tinylab;
  window.shakerspace = shakerspace;
}

export { cheatCodes, consoleFun };
