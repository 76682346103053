import ajaxCall from '../lib/ajaxCall';
import initSlickSlider from '../lib/slickSlider';
import initComments from '../lib/comments';
import { fixTop, optionsSharing } from '../lib/helper';

function initLoadMore() {
  ajaxCall({
    template: 'blog.json',
    appendToSelector: '#articles',
    triggerSelector: '#LoadMore',
  });
}

function initBlogGallery() {
  initSlickSlider();
}

function initStyles() {
  fixTop();
}

function initShare() {
  optionsSharing();
}

export default {
  init() {
    // JavaScript to be fired on the people page
    initBlogGallery();
    initLoadMore();
    initStyles();
    initComments();
    initShare();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
