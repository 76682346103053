import $ from 'jquery';
import debounce from 'lodash.debounce';

function initLogo() {
  const wrapper = $('.logo-wrapper');
  const logo = wrapper.find('#brandlogo');
  const wrapperShare = $('.wrapper-share');

  const resize = function resize() {
    if ($(window).width() > 990) {
      wrapper.removeClass('fixedsize');
    }

    if ($(window).width() <= 550) {
      wrapper.addClass('mini');
    } else {
      wrapper.removeClass('mini');
    }
    logo.fadeTo('slow', 1);
  };

  resize();

  if (wrapperShare.length > 0) {
    const status = wrapperShare.find('.status');

    if (status.length > 0) {
      wrapper.addClass('share-element-post');
    } else {
      wrapper.addClass('share-element');
    }
  }

  $(window).resize(debounce(resize, 500));

  window.addEventListener(
    'orientationchange',
    () => {
      debounce(resize, 500);
    },
    false,
  );
}

export default initLogo;
