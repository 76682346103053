import $ from 'jquery';
import Headroom from 'headroom.js';

import { cheatCodes, consoleFun } from '../lib/businessIntelligence';
import initLogo from '../lib/logo';
import { modalHandler } from '../lib/helper';

window.jQuery = $;
window.$ = $;

function initNav() {
  const expand = $('.expand');
  const menuBut = $('.menu-but');
  const content = $('.content-wrapper');
  const navi = $('.navigation');
  const naviWrapper = $('.wrapper-nav');
  const searchBut = $('.search-but');
  const modal = $('.bottom-modal');
  const hamburger = $('.hamburger');
  const logo = $('.logo');
  const header = $('.header');
  const logoWrapper = $('.logo-wrapper');
  // const stickyNavMenu = $('.sticky .menu-but');

  $('.navigation').mCustomScrollbar({
    theme: 'white-thin',
    scrollInertia: 100,
    autoDraggerLength: true,
  });

  // menu animation
  function MenuAnim() {
    if (menuBut.hasClass('opened')) {
      menuBut.removeClass('opened');
      content.removeClass('animated');
      navi.removeClass('visible');
      naviWrapper.removeClass('visible');
      header.removeClass('navicolor');
      hamburger.removeClass('is-active');
      logo.removeClass('inversed');

      logoWrapper.removeClass('fixedsize');
      logoWrapper.removeClass('navopen');
      logoWrapper.removeClass('shift-left');
    } else {
      menuBut.addClass('opened');
      content.addClass('animated');
      navi.addClass('visible');
      naviWrapper.addClass('visible');
      header.addClass('navicolor');
      modal.removeClass('expanded-s');
      searchBut.removeClass('opened');
      logo.addClass('inversed');
      logoWrapper.addClass('navopen');
      hamburger.addClass('is-active');

      if ($(window).width() <= 990) {
        logoWrapper.addClass('fixedsize');
      }
      if ($(window).width() <= 550) {
        logoWrapper.addClass('shift-left');
      }
    }
  }

  menuBut.on('click', function click() {
    if ($(this).hasClass('stickmenu')) {
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        0,
      );
      setTimeout(() => {
        MenuAnim();
      }, 0);
    } else {
      MenuAnim();
    }
  });

  expand.on('click', function click() {
    // eslint-disable-next-line
    event.preventDefault();
    if (
      $(this)
        .next('.expandable')
        .hasClass('expanded')
    ) {
      $(this).removeClass('active');
      $(this)
        .next('.expandable')
        .slideUp()
        .removeClass('expanded');
    } else {
      $(this).addClass('active');
      $(this)
        .next('.expandable')
        .slideDown()
        .addClass('expanded');
    }
  });
}

function initSearch() {
  modalHandler('.search-but', '.search-form');
}

function initCookieNotice() {
  const cookies = document.cookie.split('; ');

  if (!cookies.includes('mvlb.cookie_accepted=true')) {
    const modal = $('#CookieNotice');

    modal.find('.accept').click((e) => {
      e.preventDefault();

      const expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      const expires = `expires=${expiryDate.toGMTString()}`;
      const cookieContent = `mvlb.cookie_accepted=true; ${expires}`;

      document.cookie = cookieContent;
      modal.removeClass('expanded');
    });

    setTimeout(() => {
      modal.addClass('expanded');
    }, 7000);
  }
}

function initAnnouncement() {
  const cookies = document.cookie.split('; ');

  if (!cookies.includes('mvlb.announcement_seen=true')) {
    const modal = $('.bottom-modal.announcement');

    modal.find('.approve').click((e) => {
      e.preventDefault();

      const expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      const expires = `expires=${expiryDate.toGMTString()}`;
      const cookieContent = `mvlb.announcement_seen=true;  ${expires}`;

      document.cookie = cookieContent;
      modal.removeClass('expanded-s');
    });

    setTimeout(() => {
      modal.addClass('expanded-s');
    }, 2000);
  }
}

function initNewsletter() {
  const urlParams = new URLSearchParams(window.location.search);
  const link = $('a[href$="/newsletter"]');

  modalHandler('a[href$="/newsletter"]', '.newsletter-form');

  link.click((event) => {
    event.preventDefault();
  });

  if (urlParams.has('show_newsletter') && urlParams.get('show_newsletter') === 'true') {
    link.trigger('click');
  }
}

function initStickyNav() {
  const header = document.querySelector('#header');
  const headroom = new Headroom(header);
  headroom.init();
}

function initToTop() {
  $('.gotop, .gotop-m').on('click', () => {
    $('body,html').animate(
      {
        scrollTop: 0, // Scroll to top of body
      },
      500,
    );
  });
}

function initFooter() {
  const footer = $('.footer');
  const arrow = footer.find('.gotop');

  const trigger = () => {
    if ($(window).scrollTop() > 300) {
      arrow.removeClass('nopeek');
    } else {
      arrow.addClass('nopeek');
    }

    if (!footer.isInViewport()) {
      arrow.addClass('fixed');
    } else {
      arrow.removeClass('fixed');
    }
  };

  trigger();

  window.addEventListener('scroll', () => {
    trigger();
  });
}

function initOpenMoji() {
  $('i.om').each(function each() {
    const el = $(this);
    const unicode = el.attr('data-unicode');

    $.get(`/assets/images/openmoji/${unicode}.svg`, (data) => {
      const span = document.createElement('span');
      span.className = `om ${unicode}`;
      span.innerHTML = new XMLSerializer().serializeToString(data.documentElement);

      el.after(span).remove();
    });
  });
}

function initBusinessIntelligence() {
  const consoleStyle = 'color: #009600; background-color: #383838; font-size: 18px;';
  // eslint-disable-next-line no-console
  console.log('%c Hey hackerperson, run rtfm() to see some cool features!', consoleStyle);
  // eslint-disable-next-line no-console
  console.log('%c Hack the planet!', consoleStyle);

  consoleFun();
  cheatCodes();
}

export default {
  init() {
    // JavaScript to be fired on all pages
    initOpenMoji();
    initLogo();
    initToTop();
    initNav();
    initStickyNav();
    initFooter();
    initSearch();
    initNewsletter();
    initAnnouncement();
    initBusinessIntelligence();
    initCookieNotice();
    // console.log('should be fired');
  },
  finalize() {
    // console.log('ajunge');
  },
};
