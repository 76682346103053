import $ from 'jquery';

function getModalContent(uid) {
  const modalWrapper = $('#personmodal');
  $.ajax({
    url: `/people.json?uid=${uid}`,
    dataType: 'json',
    success(res) {
      modalWrapper
        .find('.modal-dialog')
        .empty()
        .append(res);

      modalWrapper.modal('show');
    },
  });
}

function initSlideShow() {
  $('.wrapper-slides').slick({
    mobileFirst: true, // add this one
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    adaptiveHeight: false,
    centerMode: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function initUriTriggeredModal() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('uid')) {
    getModalContent(urlParams.get('uid'));
  }
}

function initModal() {
  $('.call-modal, .wrapper-avatar a').click(function click(e) {
    e.preventDefault();
    const uid = $(this)
      .parent()
      .hasClass('wrapper-avatar')
      ? $(this)
        .parent()
        .parent()
        .find('.call-modal')
        .data('uid')
      : $(this).data('uid');

    getModalContent(uid);
    window.history.pushState({}, document.title, `${window.location.pathname}?uid=${uid}`);
  });

  $('#personmodal').on('hidden.bs.modal', () => {
    window.history.pushState({}, document.title, window.location.pathname);
  });
}

export default {
  init() {
    // JavaScript to be fired on the people page
    initSlideShow();
    initModal();
    initUriTriggeredModal();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
