// import $ from 'jquery';

// function initInfoPanel() {
//   const infoPanel = $('.infopanel');
//   const closePanel = $('.close-panel');
//   const randomShowTime = Math.random() * (35000 - 10000) + 10000;
//   const randomHideTime = randomShowTime + 6000;
// eslint-disable-next-line max-len
//   const randomHeightNumber = Math.random() * (window.innerHeight - infoPanel.innerHeight() - 0) + 0;
//   const randomScrollSpeed = Math.random() * (0.1 - 0.8) + 0.8;
//   const stepper = 7;

//   let position = 0;
//   let lastScrollTop = 0;

//   const handleParallax = () => {
//     const st = window.pageYOffset || document.documentElement.scrollTop;

//     if (st > lastScrollTop) {
//       position += stepper;
//     } else {
//       position -= stepper;
//     }

//     lastScrollTop = st <= 0 ? 0 : st;
//     infoPanel.css('bottom', `${position + randomHeightNumber * randomScrollSpeed}px`);
//   };

//   handleParallax();

//   setTimeout(() => {
//     setTimeout(() => {
//       infoPanel.addClass('noanimate');
//       window.addEventListener('scroll', handleParallax);
//     }, 500);
//     infoPanel.addClass('active');

//     setTimeout(() => {
//       closePanel.trigger('click');
//     }, randomHideTime);
//   }, randomShowTime);

//   closePanel.click((e) => {
//     e.preventDefault();
//     infoPanel.removeClass('noanimate').removeClass('active');
//     setTimeout(() => {
//       infoPanel.css('display', 'none');
//     }, 300);
//     window.removeEventListener('scroll', handleParallax);
//   });
// }

function initVideo() {
  const wrapper = document.getElementsByClassName('video-wrapper');

  if (wrapper.length >= 1) {
    const vid = wrapper[0].getElementsByTagName('video')[0];

    vid.defaultPlaybackRate = 0.5;
    vid.load();

    setTimeout(() => {
      vid.play();
    }, 3000);
  }
}

export default {
  init() {
    // JavaScript to be fired on the home page
    // initInfoPanel();
    initVideo();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
