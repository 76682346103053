import $ from 'jquery';

// import external dependencies
import 'bootstrap';
import 'jquery-mousewheel';
import 'slick-carousel';
import 'malihu-custom-scrollbar-plugin';

// import local dependencies
import Router from './lib/Router';
import common from './routes/common';
import home from './routes/home';
import people from './routes/people';
import projects from './routes/projects';
import blog from './routes/blog';
import upcoming from './routes/upcoming';
import appearances from './routes/appearances';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  people,
  projects,
  blog,
  upcoming,
  appearances,
});

// Load Events
$(document).ready(() => routes.loadEvents());
